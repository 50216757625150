import Top from './media/Gorilla4.webp';
import './styles/App.css';
import backGroundRep from './media/backgroundRepBeschreibung.webp';
import Displaytausch from './media/Displaytausch.webp';
import Wasserschaden from './media/Wasserschaden.webp';
import Akkutausch from './media/Akkutausch.webp';
import Schutzfolie from './media/Schutzfolie.webp';
import Garantie from './media/Garantie.webp';
import Icon1 from './media/icon1.webp';
import Icon2 from './media/icon2.webp';
import Icon3 from './media/icon3.webp';
import CircularProgress from "@mui/material/CircularProgress";
import AOS from 'aos';
import "aos/dist/aos.css";
import { useEffect, useState } from 'react';
import ReviewsComponent from './komponenten/Reviews';
import { connection } from './constants/global';
import { Modal } from 'flowbite';
import type { ModalOptions, ModalInterface } from 'flowbite';

interface Kategorien {
  kategorienName: string;
  id: number;
  logoName: string;
}

interface PopUp {
  title: string;
  text: string;
  active: boolean;
}

const getPopUpData = async () => {
  const response = await fetch(connection + '/MacAndPhoneDoc/GetPopUpData');
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const PopUpInfo: PopUp[] = await response.json();
  return PopUpInfo;
};
function Startseite() {




  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);


  const [kategorien, setKategorien] = useState<Kategorien[]>([]);
  const [openModal, setOpenModal] = useState(true);

  const api = async () => {
    const resultData = await fetch(connection + "/MacAndPhoneDoc/GetCategories", { method: "GET" });

    const resultJsonData = await resultData.json();

    setKategorien(resultJsonData);
  }
  useEffect(() => {
    const modalBackdrop = document.querySelectorAll('[modal-backdrop]');

    if (modalBackdrop) {
      modalBackdrop.forEach((element) => {
        element.remove();
      });
      setActive(false);

    }
  }, [openModal]);
  useEffect(() => {
    
      const $modalElement = document.getElementById('default-modal');
      if ($modalElement) {
        const modalOptions: ModalOptions = {
          placement: 'bottom-right',
          backdrop: 'dynamic',
          backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
          closable: true,
          onHide: () => {
            console.log('modal is hidden');
          },
          onShow: () => {
            console.log('modal is shown');
          },
          onToggle: () => {
            console.log('modal has been toggled');
          },
        };
        console.log($modalElement);
        const modal: ModalInterface = new Modal($modalElement, modalOptions);
        if (active) {
        modal.show(); // Show the modal if needed
      } else {
        modal.hide();
      }
      } else {
        console.error('Modal element not found');
      }
    
  }, [active]);
  useEffect(() => {
    setLoading(true);
    api();
    AOS.init();
    AOS.refresh();
    getPopUpData()
      .then(infos => {
        if (infos && infos.length > 0) {
          const data = infos[0];
          setTitle(data.title);
          setText(data.text);
          setActive(data.active);
        }
        setLoading(false);

      })
      .catch(error => {
        console.error(error);
        setError('Fehler beim Laden der Daten.');
      });
  }, []);
  function closeModal(): void {
    const $modalElement = document.getElementById('default-modal');
    if ($modalElement) {
      const modalOptions: ModalOptions = {
        placement: 'bottom-right',
        backdrop: 'dynamic',
        backdropClasses: 'fixed inset-0 z-[-9]',
        closable: true,
        onHide: () => {
          console.log('modal is hidden');
        },
        onShow: () => {
          console.log('modal is shown');
        },
        onToggle: () => {
          console.log('modal has been toggled');
        },
      };
      console.log($modalElement);
      const modal: ModalInterface = new Modal($modalElement, modalOptions);
      modal.hide(); // Show the modal if needed
    } else {
      console.error('Modal element not found');
    }
  }

  return (
    <>
      <div className="App scroll-smooth hover:scroll-auto">
        {active && <div id="default-modal" tabIndex={-1} aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative p-4 w-full max-w-2xl max-h-full">

            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {title}
                </h3>
                <button type="button" onClick={() => (setOpenModal(false),closeModal())} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-4 md:p-5 space-y-4 popup-preview">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  {text}
                </p>
              </div>
            </div>
          </div>
        </div>}
        <div className="z-30 mx-auto relative  lg:flex lg:items-center  bg-primary text-stone-50 ">

          <div className='lg:flex-none px-6  py-12   lg:w-1/2 2xl:pl-96 aos-init aos-animate' data-aos="fade-right">
            <div className=" w-11/12">
              <p className="text-base  font-medium uppercase">Wir sind Ihr ultimativer Ansprechpartner für alles was mit Mobilgeräten zu tun hat.</p>
              <h1 className="text-5xl font-semibold text-stone-50 uppercase">Kaputtes Handy?</h1>
              <h2 className="text-4xl font-semibold text-black    uppercase">Wir reparieren es!</h2>
              <p className="text-base font-light">Reparaturservice gleich am selben Tag für die meisten Geräte.Für alle Reparaturen gilt 6 Monate Garantie.</p>
            </div>
          </div>
          <div className="lg:flex-none hidden lg:block lg:w-1/2">
            <img src={Top} className="object-cover h-[60vh] w-full" alt="logo" />
          </div>
        </div>

        <div className='z-30 bg-white relative'>
          <div className=' pt-10 pb-60 lg:pb-36 px-3 reparaturen-Boxen bg-gray-100'>
            <div className='ReparaturServices  max-w-5xl mx-auto'>
              <div className='lg:flex flex-wrap'>
                <div className='ReparaturServicesBox  justify-self-center bg-no-repeat bg-center  lg:w-1/3 bg-black text-white text-4xl leading-snug' style={{ padding: "3rem 1rem", textAlign: "center" }}>Reparatur<br /> Service</div>

                {
                  kategorien.map((item, index) => {
                    return (
                      <a href={"/ReparaturInfos?kategorie=" + item.id} key={index} className='ReparaturServicesBox text-2xl h-56 block ease-in duration-300 bg-no-repeat bg-center bg-contain lg:w-1/3' style={{ backgroundImage: `linear-gradient(160deg,rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.8) 100%),url(${require("./media/" + item.logoName)})` }}>
                        <div className="h-full flex flex-col justify-center items-center">
                          <p className="text-white">{item.kategorienName}</p>
                        </div>
                      </a>
                    )
                  }
                  )
                }

              </div>
            </div>
          </div>
        </div>
        <div className='z-30 relative' style={{ background: `url(${backGroundRep}) no-repeat bottom white`, backgroundSize: "cover" }}>
          <div className='px-3 mx-auto max-w-6xl text-black overflow-x-hidden' >

            <div className='lg:flex items-center py-4'>
              <div className='flex-none lg:w-1/2 lg:mr-4'>
                <h3 className='Displaytausch-Demo-Text font-bold lg:text-3xl mb-3 '>Displaytausch</h3>
                <p>Wir machen die Reparatur und den Austausch von Handy-Bildschirmen zum Kinderspiel! Wir verstehen, wie beschäftigt Sie sind und wie wichtig es für Sie ist, ein funktionierendes Mobiltelefon zu haben. Aus diesem Grund sind wir bestrebt, schnelle Bearbeitungszeiten und außergewöhnlichen, persönlichen Service zu bieten.</p>
              </div>
              <div className='flex-none lg:w-1/2' data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease" data-aos-duration="400">
                <img className='h-96 mx-auto' src={Displaytausch} alt="Displaytausch Foto" />
              </div>
            </div>

            <div className='lg:flex items-center py-4 flex-row-reverse'>
              <div className='flex-none lg:w-1/2 ' >
                <h3 className='font-bold lg:text-3xl mb-3'>Wasserschaden</h3>
                <p>Was soll ich tun, wenn mein Gerät ins Wasser fällt? Versuchen Sie nicht, das Telefon einzuschalten, da dies zu einem schweren Kurzschluss führen kann. Als nächstes schicken Sie das Gerät zum Wasserschaden-Service ein. Die Chancen auf eine erfolgreiche Reparatur des Telefons steigen erheblich, je früher wir es erhalten.</p>
              </div>
              <div className="flex-none lg:w-1/2   lg:mr-4" data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease" data-aos-duration="400">
                <img className='h-96 mx-auto ' src={Wasserschaden} alt="Wasserschaden Foto" />
              </div>
            </div>

            <div className='lg:flex items-center py-4'>
              <div className='flex-none lg:w-1/2 lg:mr-4'>
                <h3 className='font-bold lg:text-3xl mb-3'>Batterietausch</h3>
                <p>Wenn der Akku Ihres Mobiltelefons nicht mehr lädt, können viele Ihrer täglichen Aufgaben unterbrochen werden. Wenn Sie Maßnahmen zur Schonung des Akkus ergriffen haben und der Akku immer noch nicht geladen wird, müssen Sie den Akku möglicherweise reparieren oder austauschen.</p>
              </div>
              <div className="flex-none lg:w-1/2 " data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease" data-aos-duration="400">
                <img className='h-96 mx-auto' src={Akkutausch} alt="Akkutausch Foto" />
              </div>
            </div>

            <div className='lg:flex items-center py-4 flex-row-reverse'>
              <div className='flex-none lg:w-1/2 '>
                <h3 className='font-bold lg:text-3xl mb-3'>Schutzfolie</h3>
                <p>Wir führen Schutzfolien für nahezu alle Handy-Modelle, insbesondere sogenannte Hydrogel-Folien. Diese speziell entwickelten Folien sind einfach von uns am Handy anzubringen und passen sich perfekt an. Mit unseren Schutzfolien können Sie Ihr Display effektiv vor Kratzern und Schmutz schützen. Die Hydrogel-Technologie minimiert die Bildung von Luftblasen und gewährleistet eine gleichmäßige Anbringung. Entdecken Sie den optimalen Schutz für Ihr Handy-Display mit unseren hochwertigen Schutzfolien.</p>
              </div>
              <div className="flex-none lg:w-1/2  lg:mr-4" data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease" data-aos-duration="400">
                <img className='h-96 mx-auto' src={Schutzfolie} alt="Schutzfolie Foto" />
              </div>
            </div>
          </div>
        </div>

        <div className='w-full bg-primary h-fit md:h-36 2xl:h-80 flex justify-around flex-col py-6 gap-6 lg:flex-row  items-center text-white shadow-inner'>
          <img src={Garantie} className='md:fixed top-[49vh] left-[15vw] 2xl:left-[25vw] z-10 h-40 lg:h-96 -rotate-12' alt="Garantie Foto"></img>
          <div className="md:fixed top-[60vh] md:h-screen z-10 right-[15vw] 2xl:right-[25vw]">
            <h3 className=' text-4xl font-bold mb-4'>6 Monate Garantie</h3>
            <p className='text-sm'>MacAndPhoneDoc bietet eine 6-Monatige Garantie <br /> auf alle Reparaturen!</p>
          </div>

        </div>

        <div className='w-full h-fit flex flex-col md:flex-row z-30 relative justify-around bg-white items-center text-black py-12'>
          <div className='md:w-1/3 w-full flex flex-col items-center text-center gap-5'>
            <img src={Icon1} className='h-12 w-7' alt="Wer sind wir"></img>
            <h3 className='text-lg font-semibold mb-2'>Wer wir sind</h3>
            <p className='text-sm px-4'>Wir sind führend in der Reparatur von Smartphones, Tablets und Computern. Von kaputten Bildschirmen über Wasserschäden bis hin zum Batteriewechsel. Wir reparieren es gleich beim ersten Mal und sorgen dafür, dass Ihre Daten sicher und geschützt bleiben. Wir sind ein schnelles und zuverlässiges Reparaturunternehmen für elektronische Geräte.</p>
          </div>

          <div className='md:w-1/3 w-full flex flex-col items-center text-center gap-5'>
            <img src={Icon2} className='h-8' alt="Erfahrung"></img>
            <h3 className='text-lg font-semibold mb-2'>Erfahrung</h3>
            <p className='text-sm px-4'>Unsere erfahrenen Techniker sind bestens ausgebildet und qualifiziert, um eine Vielzahl verschiedener Arten von Reparaturen durchzuführen, darunter Bildschirmaustausch, Wasserschadendiagnose und Batteriewechsel.</p>
          </div>

          <div className='md:w-1/3 w-full flex flex-col items-center text-center gap-5'>
            <img src={Icon3} className='h-8' alt="Unsere Mission"></img>
            <h3 className='text-lg font-semibold mb-2'>Unsere Mission</h3>
            <p className='text-sm px-4'>Wir verwenden für alle unsere Reparaturen nur Teile höchster Qualität und stehen hinter unserer Arbeit. In den meisten Fällen können unsere Techniker Ihr Gerät in nur wenigen Minuten reparieren. Normalerweise bringen wir Ihr Telefon innerhalb von 45 Minuten oder weniger zurück.</p>
          </div>
        </div>

        <div className=' bg-white z-30 relative'>
          <div className='h-fit bg-primary py-52 ' style={{ clipPath: "polygon(0 150px, 100% 0, 100% 100%, 0% 100%)" }}>
            <ReviewsComponent />
          </div>
        </div>


      </div>
    </>
  );
}

export default Startseite;
