


const Transfer = () => {
    return (
        <div className='bg-primary p-10'>
            <div className='w-full mx-auto bg-white p-3 md:p-10 rounded-3xl'>
                <div className='w-full  lg:px-0 px-4 flex flex-col content-center flex-wrap min-h-screen gap-10 text-gray-500'>
                    <div className='w-full flex flex-col content-center flex-wrap items-center'>
                        <h1 className='text-2xl sm:text-4xl lg:text-5xl font-bold w-fit'>Datenübertragung von Smartphone zu Smartphone</h1>

                        <div className="mt-10 ">
                            <div className="mb-4 ">
                                <h2 className='font-bold text-lg lg:text-3xl   '>Warum sollten Sie sich für unsere Dienstleistung entscheiden?</h2>
                                <p><strong>1. Einfach und Bequem:</strong> Unsere erfahrenen Techniker machen die Datenübertragung zu einem kinderleichten Prozess. Sie müssen sich keine Sorgen um komplizierte Anleitungen oder verlorene Daten machen.</p>
                                <p><strong>2. Vielfältige Datenarten:</strong> Wir können Fotos, Videos, Kontakte, Kalendereinträge und SMS von einem Gerät zum anderen übertragen.</p>
                                <p><strong>3. Betriebssystemspezifische Einschränkungen:</strong> Apps können nicht betriebssystemübergreifend kopiert werden. Die Übertragung von App-Dateien ist nur von iPhone zu iPhone möglich.</p>
                                <p><strong>4. Sicherheit steht an erster Stelle:</strong> Wir behandeln Ihre Daten mit höchster Vertraulichkeit und Sicherheit. Ihre persönlichen Informationen sind bei uns in sicheren Händen.</p>
                                <p><strong>5. Schnell und Effizient:</strong> Unsere Techniker nutzen effiziente Methoden, um die Datenübertragung so schnell wie möglich abzuschließen, damit Sie Ihr neues Smartphone sofort in vollem Umfang nutzen können.</p>
                            </div>
                            <div className="mb-4">
                                <h2 className='font-bold text-lg lg:text-3xl   '>Kosten:</h2>
                                <p><strong>iPhone zu iPhone:</strong> ab 30€</p>
                                <p><strong>Android zu Android:</strong> ab 30€</p>
                                <p><strong>iPhone zu Android:</strong> ab 40€</p>
                                <p><strong>Android zu iPhone:</strong> ab 40€</p>
                                <p><em>Hinweis: Bei einem defekten Display des alten Geräts wird ein Aufpreis von 30€ berechnet. Dieser Aufpreis ist notwendig, da wir das Gerät zerlegen und einen provisorischen Bildschirm einbauen müssen, um das Gerät bedienen zu können.</em></p>
                            </div>
                            <div className="mb-4">
                                <h2 className='font-bold text-lg lg:text-3xl   '>Unterstützte Dateien:</h2>
                                <p><strong>Kopierbar:</strong> Fotos, Videos, Kontakte, Kalendereinträge, SMS</p>
                                <p><strong>Nicht kopierbar (betriebssystemübergreifend):</strong> Apps</p>
                                <p><strong>Kopierbar (nur von iPhone zu iPhone):</strong> App-Dateien</p>
                            </div>
                            <div className="mb-4">
                                <h2 className='font-bold text-lg lg:text-3xl   '>So funktioniert es:</h2>
                                <p><strong>1. Besuchen Sie uns:</strong> Kommen Sie einfach in unserem Servicecenter vorbei und bringen Sie sowohl Ihr altes als auch Ihr neues Smartphone mit.</p>
                                <p><strong>2. Datenübertragung:</strong> Unsere Experten übertragen Fotos, Videos, Kontakte, Kalendereinträge und SMS von Ihrem alten Smartphone auf das neue. Beachten Sie die betriebssystemspezifischen Einschränkungen für Apps und App-Dateien.</p>
                                <p><strong>3. Optionale Services:</strong> Nutzen Sie die Gelegenheit, um zusätzliche Services wie Datenbereinigung, Sicherheitsüberprüfungen oder die Installation neuer Apps auf Ihrem neuen Gerät in Anspruch zu nehmen.</p>
                                <p><strong>4. Support nach der Übertragung:</strong> Unser Support endet nicht mit der Datenübertragung. Falls Sie nach dem Vorgang weitere Fragen haben oder Unterstützung benötigen, stehen wir Ihnen gerne zur Verfügung.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Transfer;