import React, { useEffect, useRef } from 'react';
import Top from '../media/MacAndPhoneLogo.svg';
import '../styles/App.css';

import SearchBarComp from './SearchBarComp';

function Navbar() {
    const [show, setShow] = React.useState(false);
    const [showReps, setShowReps] = React.useState(false);
    const menuRef = useRef(null);
    const navbarRef = useRef<any>(null);
    const repRef = useRef<any>(null);
    const repButtonRef = useRef<any>(null);
    function toggleReps() {
        setShowReps(!showReps);
    }

    function toggle() {
        setShow(!show);
    }
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {

            if (
                (menuRef.current && !(menuRef.current as any).contains(event.target)) &&
                (repRef.current && !repRef.current.contains(event.target as Node)) &&
                (repButtonRef.current && !repButtonRef.current.contains(event.target as Node)) &&
                (navbarRef.current && !navbarRef.current.contains(event.target as Node))
            ) {
                
                setShow(false);
                setShowReps(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);


    return (
        <header className="Navbar h-fit grid grid-flow-col  sticky top-0 bg-white w-full z-40">
            <nav ref={navbarRef} className="bg-white border-gray-200 dark:bg-gray-900">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
                    <a href='./' className="flex items-center">
                        <img  src={Top} className="h-14" alt="logo" />
                    </a>
                    <div className="flex md:order-2">
                        <button type="button" onClick={toggle} className="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 mr-1">
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span className="sr-only">Search</span>
                        </button>
                        <div className="relative hidden md:block">

                            <SearchBarComp />
                        </div>
                        <button onClick={toggle} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" >
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button>
                    </div>
                    <div ref={menuRef} className={`items-center justify-between ${!show && "hidden"} w-full md:flex md:w-auto md:order-1`} id="mega-menu">
                        <div className="relative mt-3 md:hidden">
                            <SearchBarComp />
                        </div>
                        <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li>
                                <a href="/" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" aria-current="page">Home</a>
                            </li>

                            <li style={{position: "relative"}}>
                                <button onClick={toggleReps} ref={repButtonRef} className="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium text-gray-900 border-b border-gray-100 md:w-auto hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-blue-500 md:dark:hover:bg-transparent dark:border-gray-700">
                                    Reparaturen
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                <div ref={repRef} className={`absolute z-10 ${showReps === true ? "grid" : "hidden"} w-auto grid-cols-2 text-sm bg-white border border-gray-100 rounded-lg shadow-md dark:border-gray-700  dark:bg-gray-700 min-h-[120px] min-w-[300px]`} style={{position: "absolute", inset: "0px auto auto 0px", margin:" 0px", transform: "translate(10px, 40px)"}}>
                                    <div className="p-4 pb-0 text-gray-900 md:pb-4 dark:text-white">
                                        <ul className="space-y-4" aria-labelledby="mega-menu-icons-dropdown-button">

                                            <li>
                                                <a href="/ReparaturInfos?kategorie=1" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                    <span className="sr-only">iPhone</span>

                                                    iPhone
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ReparaturInfos?kategorie=2" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                    <span className="sr-only">iPad</span>

                                                    iPad
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ReparaturInfos?kategorie=3" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                    <span className="sr-only">Smartphone</span>

                                                    Smartphone
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ReparaturInfos?kategorie=4" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                    <span className="sr-only">Macbook / iMac</span>

                                                    Macbook / iMac
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/ReparaturInfos?kategorie=5" className="flex items-center text-gray-500 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-500 group">
                                                    <span className="sr-only">iWatch</span>

                                                    iWatch
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="/UeberUns" className={`block ${showReps === true ? "pt-[200px]" : ""} py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}>Über uns</a>
                            </li>
                            <li>
                                <a href="/DerReparaturbonus" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Der Reparaturbonus</a>
                            </li>
                            <li>
                                <a href="/Kontakt" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Kontakt</a>
                            </li>
                            <li>
                                <a href="/DatenTransfer" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">DatenTransfer</a>
                            </li>
                            <li>
                                <a href="/FAQ" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">FAQ</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </header >
    );
}

export default Navbar;
